'use strict';

let formValidation = require('../components/formValidation');
let createErrorNotification = require('../components/errorNotification');

const getRecaptchaToken = function (parent, action) {
  if (parent) {
    const googleRecaptchaClientSide = parent.querySelector('input[name="googleRecaptchaClientSide"');
    const recaptchaToken = parent.querySelector('[name="googleRecaptchaToken"]');
    if (googleRecaptchaClientSide && typeof window.grecaptcha !== 'undefined') {
      window.grecaptcha.ready(function () {
        window.grecaptcha.execute(googleRecaptchaClientSide.value, { action: action }).then(function (token) {
          recaptchaToken.setAttribute('value', token);
        });
      });
    }
  }
};

module.exports = {
  login: function () {
    $('form.login').on('bouncerFormValid', function (e) {
      let form = $(this);
      e.preventDefault();
      let url = form.attr('action');
      //form.spinner().start();
      $('form.login').trigger('login:submit', e);
      $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: form.serialize(),
        success: function (data) {
          if (data.dataLayer) {
            window.dataLayer.push(data.dataLayer);
          }
          if (!data.success) {
            formValidation(form, data);
            $('form.login').trigger('login:error', data);
          } else {
            $('form.login').trigger('login:success', data);
            if (window.klaviyo && data.email) {
                window.klaviyo.push(["identify", {
                email: data.email,
              }]);
            }
            location.href = data.redirectUrl;
          }
        },
        error: function (data) {
          if (data.responseJSON.redirectUrl) {
            window.location.href = data.responseJSON.redirectUrl;
          } else {
            $('form.login').trigger('login:error', data);
            //form.spinner().stop();
          }
        },
      });
      return false;
    });
  },

  register: function () {
    getRecaptchaToken($('form.registration')[0], 'RegistrationForm');
    $('form.registration').on('bouncerFormValid', function (e) {
      let form = $(this);
      e.preventDefault();
      let url = form.attr('action');
      //form.spinner().start();
      $('form.registration').trigger('login:register', e);
      $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: form.serialize(),
        success: function (data) {
          if (data.dataLayer) {
            window.dataLayer.push(data.dataLayer);
          }
          if (!data.success) {
            $('form.registration').trigger('login:register:error', data);
            formValidation(form, data);
          } else {
            $('form.registration').trigger('login:register:success', data);
            if (window.klaviyo && data.email) {
              window.klaviyo.push(["identify", {
              email: data.email,
            }]);
          }
            location.href = data.redirectUrl;
          }
          getRecaptchaToken($('form.registration')[0], 'RegistrationForm');
        },
        error: function (err) {
          if (err.responseJSON.redirectUrl) {
            window.location.href = err.responseJSON.redirectUrl;
          } else {
            createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
          }
          getRecaptchaToken($('form.registration')[0], 'RegistrationForm');
        },
      });
      return false;
    });
  },

  resetPassword: function () {
    $('.reset-password-form').on('bouncerFormValid', function (e) {
      let form = $(this);
      e.preventDefault();
      let url = form.attr('action');
      //form.spinner().start();
      $('.reset-password-form').trigger('login:register', e);
      $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: form.serialize(),
        success: function (data) {
          if (data.dataLayer) {
            window.dataLayer.push(data.dataLayer);
          }
          if (!data.success) {
            formValidation(form, data);
          } else {
            $('.request-password-title').text(data.receivedMsgHeading);
            $('.request-password-body')
              .empty()
              .append('<p>' + data.receivedMsgBody + '</p>');
            if (!data.mobile) {
              $('.send-email-btn').html('<a href="' + data.returnUrl + '" class="btn btn-primary btn-block">' + data.buttonText + '</a>');
            } else {
              $('.send-email-btn')
                .empty()
                .html('<a href="' + data.returnUrl + '" class="btn btn-primary btn-block">' + dpageata.buttonText + '</a>');
            }
          }
        },
        error: function () {
          //form.spinner().stop();
        },
      });
      return false;
    });
  },

  setNewPassword: function () {
    $('.change-password-form').submit(function (e) {
      let form = $(this);
      e.preventDefault();
      let url = form.attr('action');

      $('.change-password-form').trigger('password:edit', e);
      $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: form.serialize(),
        success: function (data) {
          if (!data.success) {
            formValidation(form, data);
          } else {
            $('.change-password-title').text(data.receivedMsgHeading);
            $('.change-password-sub-title').text(data.receivedMsgSubTitle);
            $('.new-password-body')
              .empty()
              .append('<p>' + data.receivedMsgBody + '</p>');
            $('.change-password-btn')
              .empty()
              .html('<a href="' + data.buttonUrl + '" class="btn btn-primary btn-block">' + data.buttonText + '</a>');
          }
        },
        error: function () {
          // form.spinner().stop();
        },
      });
      return false;
    });
  },

  clearResetForm: function () {
    $('#login .modal').on('hidden.bs.modal', function () {
      $('#reset-password-email').val('');
      $('.modal-dialog .form-control.is-invalid').removeClass('is-invalid');
    });
  },
};
